import React from 'react';
import Layout from 'components/layout';
import c from 'classnames';

import wrappers from 'styles/_wrappers.module.scss';
import helpers from 'styles/_helpers.module.scss';

const PrivacyPolicy = props => (
  <Layout title="Política de Privacidade" location={props.location}>
    <div className={c(wrappers.wrapper, helpers.center)}>
      <h1 className={helpers.mt20}>Política de Privacidade</h1>
      <p className={helpers.mt20}>Última Alteração: 28 de Fevereiro de 2018.</p>

      <p className={helpers.mt20}>
        A Redlight Software construiu o aplicativo CPC Anotado como um
        aplicativo “Freemium”. Este SERVIÇO é fornecido pelo Redlight Software
        sem nenhum custo e destina-se a ser usado como está. Esta página é usada
        para informar aos visitantes do site sobre nossas políticas com a
        coleta, uso e divulgação de Informações Pessoais se alguém decidiu usar
        nosso Serviço.
      </p>

      <p className={helpers.mt20}>
        Se você optar por usar nosso Serviço, você concorda com a coleta e uso
        de informações em relação a esta política. As Informações Pessoais que
        coletamos são usadas para fornecer e melhorar o Serviço. Não usaremos ou
        compartilharemos suas informações com ninguém, exceto conforme descrito
        nesta Política de Privacidade.
      </p>

      <p className={helpers.mt20}>
        Os termos utilizados nesta Política de Privacidade têm os mesmos
        significados que nos nossos Termos e Condições, que está disponível no
        CPC Anotado, a menos que seja definido de outra forma nesta Política de
        Privacidade.
      </p>

      <p className={helpers.mt20}>
        Para uma melhor experiência, serão coletadas informações para o Uso; Ao
        usar nosso serviço, podemos exigir que você nos forneça certas
        informações pessoalmente identificáveis. A informação que solicitamos é
        será mantida por nós e usada conforme descrito nesta política de
        privacidade.
      </p>

      <p className={helpers.mt20}>
        O aplicativo usa serviços terceirizados que podem coletar informações
        usadas para identificá-lo.
      </p>

      <p className={helpers.mt20}>
        Apple AppStore - Política de Privacidade. Google Play - Política de
        Privacidade. Mixpanel - Política de Privacidade.
      </p>

      <p className={helpers.mt20}>
        Desejamos informá-lo que sempre que você usar o nosso Serviço, em caso
        de erro no aplicativo, coletamos dados e informações (através de
        produtos de terceiros) em seu telefone chamado Log Data. Esses dados do
        registro podem incluir informações como o endereço do seu dispositivo
        (IP), o nome do dispositivo, a versão do sistema operacional, a
        configuração do aplicativo quando utilizar o nosso Serviço, a hora e a
        data do uso do Serviço e outras estatísticas. Cookies - Os cookies são
        arquivos com uma pequena quantidade de dados que é comumente usado como
        identificador exclusivo anônimo. Estes são enviados para o seu navegador
        a partir do site que você visita e são armazenados na memória interna do
        dispositivo. Este serviço não usa esses &quot;cookies&quot;
        explicitamente. No entanto, o aplicativo pode usar código de terceiros e
        bibliotecas que usam &quot;cookies&quot; para informações de coleta e
        para melhorar seus serviços.
      </p>

      <p className={helpers.mt20}>
        Você tem a opção de aceitar ou recusar esses cookies e saber quando um
        cookie está sendo enviado para o seu dispositivo. Se você optar por
        recusar nossos cookies, talvez você não consiga usar algumas partes
        desse Serviço. Provedores de serviços podem empregar empresas
        terceirizadas e pessoas físicas devido às seguintes razões: • Para
        facilitar o nosso Serviço; • Fornecer o Serviço em nosso nome; • Para
        executar serviços relacionados ao serviço; ou • Para nos ajudar a
        analisar como nosso Serviço é usado. Queremos informar aos usuários
        deste Serviço que estes terceirizados têm acesso às suas Informações
        Pessoais. A razão é executar as tarefas que lhes são atribuídas em nosso
        nome. No entanto, eles são obrigados a não divulgar ou usar a informação
        para qualquer outra finalidade.
      </p>

      <p className={helpers.mt20}>
        Segurança: Nós valorizamos sua confiança em nos fornecer suas
        Informações Pessoais, portanto estamos nos esforçando para usar meios
        comercialmente aceitáveis ​​para protegê-la. Mas lembre-se de que nenhum
        método de transmissão através da internet, ou método de armazenamento
        eletrônico é 100% seguro e confiável, e não podemos garantir sua
        segurança absoluta.
      </p>

      <p className={helpers.mt20}>
        Links para outros sites: Este serviço pode conter links para outros
        sites. Se você clicar em um link de terceiros, você será direcionado
        para esse site. Observe que esses sites externos não são operados por
        nós. Portanto, recomendamos que você analise a Política de Privacidade
        desses sites. Não temos controle e não assumimos qualquer
        responsabilidade pelo conteúdo, políticas de privacidade ou práticas de
        qualquer site ou serviço de terceiros.
      </p>

      <p className={helpers.mt20}>
        Privacidade Infantil: Estes Serviços não abordam pessoas com menos de 13
        anos de idade. Nós não coletamos informações de identificação pessoal de
        crianças menores de 13 anos. No caso, descobrimos que uma criança com
        menos de 13 anos nos forneceu informações pessoais, nós imediatamente
        excluímos isso de nossos servidores. Se você é pai ou responsável e está
        ciente de que seu filho nos forneceu informações pessoais, entre em
        contato conosco para que possamos realizar as ações necessárias.
      </p>

      <p className={helpers.mt20}>
        Alterações nesta Política de Privacidade: Podemos atualizar nossa
        Política de Privacidade de tempos em tempos. Assim, você é aconselhado a
        revisar esta página periodicamente para qualquer alteração. Nós
        notificaremos você de quaisquer alterações publicando a nova Política de
        Privacidade nesta página. Essas alterações são efetivas imediatamente
        após serem publicadas nesta página. Entre em contato conosco se você
        tiver alguma dúvida ou sugestão sobre nossa Política de privacidade, não
        hesite em nos contactar.
      </p>
    </div>
  </Layout>
);

export default PrivacyPolicy;
